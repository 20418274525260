import { message } from "antd";
import API from "../config/apis";

const POST = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Accept-Language", "en-US,en;q=0.9");
      myHeaders.append("Connection", "keep-alive");
      myHeaders.append("Content-Type", "application/json");
      const requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: "follow",
      };
      const response = await fetch(`${API.BASE_URL}${url}`, requestOptions);
      const result = await response.json();
      if (response.ok) {
        resolve(result);
      } else {
        reject(result);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const GET = async (url: string, params: Record<string, any> = {}) => {
  try {
    const queryParams = new URLSearchParams(params).toString();
    const URL = queryParams ? url + `?${queryParams}` : url;
    const response = await fetch(API.BASE_URL + URL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error(errorData.message || "Something went wrong");
      (error as any).status = response.status;
      throw error;
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

const PUT = async (url: string, body: Record<string, any>) => {
  try {
    const response = await fetch(API.BASE_URL + url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      const errorData = await response.json();
      console.log("errorData");
      console.log(errorData);
      console.log("errorData");

      const error = new Error(
        errorData?.message ||
        errorData?.error?.message ||
        "Something went wrong"
      );
      (error as any).status = response.status;
      throw error;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const FILE_UPLOAD = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Supported file types
      const supportedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/illustrator",
        "application/postscript",
      ];

      // Check if the file type is supported
      if (!supportedFileTypes.includes(file.type)) {
        reject({
          status: false,
          data: "",
          message:
            "Unsupported file type. Please upload a PDF, JPG, JPEG, or PNG file.",
        });
        return;
      }

      const myHeaders = new Headers();
      const formdata = new FormData();
      formdata.append("files", file, file.name);

      const response = await fetch(`${API.BASE_URL}${API.UPLOAD}`, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      });

      const result = await response.json();

      if (result?.status) {
        resolve(result);
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      throw new Error("File upload failed");
    }
  });
};

export { POST, GET, PUT, FILE_UPLOAD };
